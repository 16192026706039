.bw-tab-nav{
    border-bottom: 0 !important;
    background: #d7dbde;
    .nav-link{
        border: 0 !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    &.active{
        background: #fff;
    }
    .nav-item{
        margin-bottom: 0 !important;
    }
}
.bw-tab-content{
    border: 0 !important;
}
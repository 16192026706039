@media (max-width: 767px) {
  .none767 { display: none !important; }
  .block767 { display: block !important; }

  .mobile-reset-width { width: 100% !important; max-width: 100 !important; min-width: 100% !important; }
  .MuiPaper-root__custom .MuiTableCell-root {
    width: 65%;
    height: auto;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.85rem;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .MuiPaper-root__custom .MuiTableCell-root:nth-of-type(2n) {
    font-weight: bold;
    width: 35%;
  }
}
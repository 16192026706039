@import '../components/Common/BlackwindUI/icons//scss/coreui-icons.scss';
@import '~flag-icon-css/css/flag-icon.min.css';
@import '~font-awesome/css/font-awesome.min.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
@import '~jqwidgets-scripts/jqwidgets/styles/jqx.bootstrap.css';
@import '../assets/scss/style.scss';

.mb5 {
    margin-bottom: 5px;
}
.mb15 {
    margin-bottom: 15px;
}
.mt15 {
    margin-top: 15px;
}
.ml10 {
    margin-left: 10px;
}
.underline {
    text-decoration: underline;
}
.center {
    text-align: center;
}
.block {
    display: block;
}
.cur-pointer {
    cursor: pointer;
}
.hidden {
    overflow: hidden;
}
.radius-50-percent {
    border-radius: 50%;
}
.ps-relative {
    position: relative;
}
.input-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0;
}
.flex {
    display: flex;
}
.felx-center {
    align-items: center;
    justify-content: center;
}
.flex-center-items {
    align-items: center;
}

.user-imgage {
    display: block;
    margin: 0 auto 15px;
    width: 180px;
    max-width: 100%;
    height: 180px;
    max-height: 100%;
    border: 1px solid silver;
    background-color: white;
    object-fit: cover;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 1101;
    overflow: auto;
}
.overlay::after {
    content: ' ';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1102;
    background-color: silver;
    opacity: 0.8;
}
.overlay-toolbars {
    text-align: right;
}
.overlay-box {
    position: relative;
    max-width: 96%;
    max-height: 96%;
    width: 960px;
    margin: 110px auto 0 auto;
    z-index: 2003;
    background-color: white;
}

#root {
    background-color: #e4e5e6;
}
